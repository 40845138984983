<template>
  <div>
    <div class="a">
      <img class="one" src="../../assets/img/WAP联系我们_02.jpg" alt="" />
    </div>
    <div class="b">
      <img class="one" src="../../assets/img/WAP联系我们_03.jpg" alt="" />
    </div>
    <div class="c">
      <img class="one" src="../../assets/img/WAP联系我们_04.jpg" alt="" />
    </div>
    <div class="d">
      <div style="height: 100%">
        <el-amap class="amap-box" vid="map" :zoom="zoom" :center="center">
          <el-amap-marker vid="marker" :position="center" :label="label">
          </el-amap-marker>
        </el-amap>
      </div>
    </div>
    <div class="e">
      <img class="one" src="../../assets/img/WAP联系我们_06.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zoom: 16,
      center: [106.54112, 29.636553],
      label: {
        content: "重庆棕榈泉",
        offset: [-26, -20],
      },
    };
  },
};
</script>

<style lang="less" scoped>
.amap-demo {
  height: 300px;
}
.e {
  height: 55px;
}
.d {
  width: 262px;
  height: 111px;
  margin: 0 auto;
}
.c {
  height: 82px;
}
.a {
  height: 403px;
}
.b {
  height: 208px;
}
</style>